/* eslint-disable prettier/prettier */
import React from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { Typography, useMediaQuery } from '@material-ui/core'

import { ParcoursProps } from '../../utils/constants/props'
import Summary from '../../components/estimation/Summary'
import PrimaryButton from '../../components/common/PrimaryButton'
import rocket from '../../assets/icons/rocket.svg'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 50,
    },
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 35,
    marginTop: -50,
    [theme.breakpoints.down('md')]: {
      marginTop: 5,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      alignItems: 'left',
    },
  },
  mainTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  subtitle: {
    color: '#67727D',
    marginTop: 5,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  userInfos: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
    width: '125%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
      marginTop: -10,
    },
  },
  infoItem: {
    color: '#67727D',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  infoItemValue: {
    color: '#001E3F',
    fontWeight: 500,
  },
  bottomButton: {
    marginBottom: 40,
    marginTop: 30,
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 40,
      marginTop: -70,
    },
  },
  name: {
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    '& > :first-of-type': {
      marginRight: 20,
    },
  },
}))

function SummaryPage({ data, ...props }: ParcoursProps): React.ReactElement {
  const matches = useMediaQuery('(max-width: 959px)')

  const formatOtherFeatures = `${
    data.balconyArea > 0 ? data.balconyArea + 'm² de terrasse/balcons - ' : ''
  } ${
    data.numberOfIndoorParkingSpaces > 1
      ? data.numberOfIndoorParkingSpaces + ' parkings intérieurs - '
      : data.numberOfIndoorParkingSpaces === 1
      ? data.numberOfIndoorParkingSpaces + ' parking intérieur - '
      : ''
  } ${
    data.numberOfOutdoorParkingSpaces > 1
      ? data.numberOfOutdoorParkingSpaces + ' parkings extérieurs - '
      : data.numberOfOutdoorParkingSpaces === 1
      ? data.numberOfOutdoorParkingSpaces + ' parking extérieur - '
      : ''
  } ${data.cellar ? 'Cave - ' : ''} ${data.hasPool ? 'Piscine - ' : ''} ${
    data.concierge ? 'Gardien - ' : ''
  } ${data.hasLift ? 'Ascenseur' : "Pas d'ascenseur"}`

  const summaryData = [
    { label: 'Adresse :', value: data.address },
    {
      label: 'Type :',
      value:
        data.propertyType +
        ' - ' +
        data.numberOfRooms.toString() +
        (data.numberOfRooms > 1 ? ' pièces - ' : ' pièce - ') +
        data.bedroomNb.toString() +
        (data.bedroomNb > 1 ? ' chambres' : ' chambre'),
    },
    {
      label: 'Superficie :',
      value:
        data.livingArea?.toString() +
        'm² habitable' +
        (data.landArea !== 0 ? ' - ' + data.landArea.toString() + 'm² terrain' : ''),
    },
    { label: 'Propriétaire :', value: data.owner ? 'Oui' : 'Non' },
    { label: 'Résidence :', value: data.property },
    { label: 'Vente du bien :', value: data.sellEstimate?.label },
    { label: 'Année de construction :', value: data.buildingYear?.label },
    { label: 'État du bien :', value: data.standing },
    { label: 'Orientation :', value: data.orientation?.label },
    { label: 'Vue :', value: data.view },
    { label: 'Autres caractéristiques :', value: formatOtherFeatures },
  ]

  const buildInfoItem = (label: string, value: string) => (
    <Typography className={classes.infoItem}>
      {label} : <span className={classes.infoItemValue}>{value}</span>
    </Typography>
  )

  const wrapOnMobile = children => {
    if (matches) {
      return <div style={{ display: 'flex', justifyContent: 'start' }}>{children}</div>
    }
    return children
  }

  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Typography variant="h4" className={classes.mainTitle}>
          Tout est bon pour vous{' '}
          <span style={{ textTransform: 'capitalize' }}>{data.contact.firstName}</span> 👍 ?
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Vous pouvez modifier vos informations aux étapes précédentes
        </Typography>
      </div>
      <div className={classes.userInfos}>
        {wrapOnMobile(
          <>
            <span className={classes.name}>{buildInfoItem('Prénom', data.contact.firstName)}</span>
            <span className={classes.name}>{buildInfoItem('Nom', data.contact.lastName)}</span>
          </>
        )}
        {buildInfoItem('E-mail', data.contact.email)}
        {buildInfoItem('Tél', data.contact.phone)}
      </div>
      <Summary data={summaryData} />
      <PrimaryButton
        icon={rocket}
        label="Lancer l'estimation"
        className={classes.bottomButton}
        onClick={() => props.changePage(false)}
      />
    </div>
  )
}

export default SummaryPage
