import paths from './constants/paths';
import { ContactInfos, Data, PropertyType } from './constants/types';
const emailRegexp = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
const phoneRegexp = /^[0-9]{10}$/;

function hasErrors(path: string, data: Data): boolean {
  let error = true;
  switch (path) {
    case paths.form.address:
      error = !data.lon || !data.lat || !(data.address[0] >= '0' && data.address[0] <= '9');
      break;
    case paths.form.type:
      error = undefined === data.propertyType ||
        data.livingArea === undefined ||
        data.livingArea < 5 ||
        (data.propertyType === PropertyType.house && !(data.landArea > 0));
      break;
    case paths.form.owner:
      error =
        data.owner === undefined || data.property === undefined || data.sellEstimate === undefined;
      break;
    case paths.form.infos:
      error = validateContactInfos(data.contact);
      break;
    default:
      error = false;
      break;
  }
  return error;
}

function validateContactInfos(contact: ContactInfos): boolean {
  if (!contact) {
    return true;
  }

  const { firstName, lastName, email, phone, gender } = contact;

  if (!firstName || !lastName || !email || !phone || !gender) return true;

  // Validate firstName and lastName
  if (firstName.length === 0 || lastName.length === 0) {
    return true;
  }

  // Validate email
  if (!isValidEmail(email)) {
    return true;
  }

  // Validate phone number
  if (!isValidPhone(phone)) {
    return true;
  }

  return false;
}

function isValidEmail(email: string): boolean {
  return email.match(emailRegexp) !== null;
}

function isValidPhone(phone: string): boolean {
  return phone.match(phoneRegexp) !== null;
}

export { hasErrors, isValidPhone, isValidEmail };
