enum PropertyType {
  apartment = 'Appartement',
  house = 'Maison',
}

enum Property {
  MAIN = 'Résidence principale',
  SECONDARY = 'Résidence secondaire',
  LOC = 'Résidence locative',
}

enum SellEstimateLabel {
  ASAP = 'Dès que possible',
  THREE_MONTHS = 'Dans 3 mois',
  SIX_MONTHS = 'Dans 6 mois',
  MORE_SIX_MONTHS = '+ de 6 mois',
  NOT_WILLING_TO_SELL = "Je n'ai pas de projet de vente",
}

type SellEstimate = {
  value: number;
  label: SellEstimateLabel;
};

enum ConstructionYearLabel {
  BEFORE_1900 = 'Avant 1900',
  _1900_1910 = '1900-1910',
  _1910_1920 = '1910-1920',
  _1920_1930 = '1920-1930',
  _1930_1940 = '1930-1940',
  _1940_1950 = '1940-1950',
  _1950_1960 = '1950-1960',
  _1960_1970 = '1960-1970',
  _1970_1980 = '1970-1980',
  _1980_1990 = '1980-1990',
  _1990_2000 = '1990-2000',
  _2000_2010 = '2000-2010',
  AFTER_2010 = 'Après 2010',
  IDK = 'Je ne sais pas',
}

type ConstructionYear = {
  value: number;
  label: ConstructionYearLabel;
};

enum Standing {
  TO_RENOVATE = 'A rénover',
  AVERAGE = 'Moyen',
  HIGH = 'Haut',
}

enum OrientationLabel {
  NORTH = 'Nord',
  SOUTH = 'Sud',
  EAST = 'Est',
  WEST = 'Ouest',
}

type Orientation = {
  value: number;
  label: OrientationLabel;
};

enum View {
  OVERLOOKED = 'Vis-à-vis',
  CLEAR = 'Dégagée',
  AMAZING = 'Exceptionnelle',
}

enum GenderLabel {
  M = 'M.',
  F = 'Mme.',
}

type Gender = {
  value: number;
  label: GenderLabel;
};

type ContactInfos = {
  gender?: Gender;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  receiveInfosFromAllowa: boolean;
  acceptContactFromAllowa: boolean;
};

type Data = {
  address: string;
  lon?: string;
  lat?: string;
  propertyType?: PropertyType;
  livingArea: number;
  landArea: number;
  floorNumber: number;
  numberOfFloorsInBuilding: number;
  owner?: boolean;
  property?: Property;
  sellEstimate?: SellEstimate;
  numberOfRooms: number;
  bedroomNb: number;
  buildingYear: ConstructionYear;
  constructionYearNone: boolean;
  standing?: Standing;
  orientation?: Orientation;
  view?: View;
  balconyArea: number;
  numberOfIndoorParkingSpaces: number;
  numberOfOutdoorParkingSpaces: number;
  hasLift: boolean;
  cellar: boolean;
  concierge: boolean;
  hasPool: boolean;
  contact: ContactInfos;
};

// Components
export type customButton = {
  label: string;
  icon?: string;
  isDisabled?: boolean;
  className?: string;
  onClick?: () => void;
  small?: boolean;
  active?: boolean;
  checkbox?: boolean;
};

export type cardServices = {
  icon: string;
  title: string;
  text?: string;
  className?: string;
};

export type cardRating = {
  name: string;
  text: string;
};

export type ratingStars = {
  score?: number;
  className?: string;
};

export type searchInput = {
  className?: string;
  data: Data;
  setData: (label: string, value: number | string | boolean) => void;
};

export {
  PropertyType,
  Property,
  Standing,
  OrientationLabel,
  View,
  GenderLabel,
  ConstructionYearLabel,
  SellEstimateLabel,
};
export type { Data, ConstructionYear, SellEstimate, Orientation, ContactInfos, Gender };
