import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// Props
import { customButton } from '../utils/constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    background: 'linear-gradient(45deg, #9AA5B0 30%, #9AA5B0 90%)',
    border: 0,
    borderRadius: 25,
    color: 'white',
    fontFamily: 'Poppins, sans-serif',
    fontSize: 15,
    height: 37,
    padding: '0 20px',
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      background: 'none',
      color: '#9AA5B0',
      fontSize: 14,
      padding: '10px 20px',
    },
    [theme.breakpoints.down('xs')]: {
      background: 'none',
      color: '#9AA5B0',
      fontSize: 12,
      padding: '10px 20px',
    },
  },
  disabledButton: {
    background: '#9AA5B0',
    color: 'white !important',
  },
}));

export default function BackButton(props: customButton): React.ReactElement {
  const classes = useStyles();
  return (
    <Button
      className={`${classes.backButton} ${props.className}`}
      classes={{ disabled: classes.disabledButton }}
      startIcon={<ChevronLeftIcon />}
      disabled={props.isDisabled}
      onClick={props.onClick}
    >
      {props.label}
    </Button>
  );
}
