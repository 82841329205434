import React from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
// Props
import { cardRating } from '../../utils/constants/types'
// Components
import Rating from '../../components/estimation/Rating'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 380,
    borderRadius: 10,
    boxShadow: '0 10px 30px rgb(0 0 0 / 10%)',
    backgroundColor: 'white',
    padding: '30px 35px',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      width: 280,
      padding: '10px 15px',
    },
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8,
    },
  },
  initialBg: {
    width: 42,
    height: 42,
    backgroundColor: '#E4EDFF',
    borderRadius: 42,
    [theme.breakpoints.down('xs')]: {
      width: 30,
      height: 30,
    },
  },
  initial: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    color: '#0E47C1',
    marginTop: 7,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      marginTop: 4,
    },
  },
  name: {
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 7,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      marginTop: 5,
    },
  },
  score: {
    marginTop: 8,
  },
  contentText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
}))

function CardRating(props: cardRating): React.ReactElement {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.userContainer}>
        <div className={classes.initialBg}>
          <Typography className={classes.initial}>{props.name.charAt(0)}</Typography>
        </div>
        <Typography className={classes.name}> {props.name}</Typography>
        <div className={classes.score}>
          <Rating score={5} />
        </div>
      </div>
      <Typography className={classes.contentText}>{props.text}</Typography>
    </div>
  )
}

export default CardRating
