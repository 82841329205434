import React from 'react'
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles'
import { Button, Typography, Tooltip, useMediaQuery } from '@material-ui/core'
import SVG from 'react-inlinesvg'
import { colours } from '../../style/variables'

import email from '../../assets/icons/email.svg'
import info from '../../assets/icons/info.svg'
import paperclip from '../../assets/icons/paperclip.svg'
import grid from '../../assets/icons/grid.svg'
import Rating from './Rating'

type CardEstimationProps = {
  price?: number
  low?: number
  high?: number
  average?: number
  confidence?: number
  onButtonClick: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: 550,
    height: 310,
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: 340,
      height: 200,
    },
  },
  content: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 35,
    paddingTop: 30,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
    },
  },
  title: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  prices: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sidePrice: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sidePriceRight: {

  },
  estimation: {
    color: '#EEFF9D',
    fontSize: 35,
    fontWeight: 800,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  sidePriceTitle: {
    color: 'white',
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      maxWidth: 80,
    },
  },
  sidePriceTitleRight: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
    },
  },
  sidePriceValue: {
    color: '#EEFF9D',
    fontSize: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
      alignSelf: 'start',
    },
  },
  sidePriceValueRight: {
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-end',
    },
  },
  averagePriceLabel: {
    color: 'white',
    marginRight: 8,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  averagePriceValue: {
    color: '#EEFF9D',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  averagePrice: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  button: {
    backgroundColor: '#E6ECF8',
    color: theme.palette.primary.main,
    borderRadius: 22,
    height: 45,
    width: 370,
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: 'white',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  buttonText: {
    color: '#0E47C1',
    fontWeight: 600,
    fontSize: 15,
    textTransform: 'none',
  },
  confidence: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: 15,
  },
  confidenceLabel: {
    color: 'white',
    fontWeight: 200,
    marginRight: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  paperclip: {
    position: 'absolute',
    top: -25,
    right: -25,
    zIndex: -1,
  },
  grid: {
    position: 'absolute',
    bottom: -100,
    left: -100,
    zIndex: -1,
  },
  rating: {
    marginRight: 10,
  },
  popin: {
    fontSize: 10,
    color: '#393939',
    fontWeight: 400,
  },
}))

function CardEstimation(props: CardEstimationProps) {
  const classes = useStyles()
  const matches = useMediaQuery('(min-width: 960px)')
  const CustomTooltip = withStyles({
    tooltip: {
      backgroundColor: `${colours.offwhite}`,
      boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.13)',
      maxHeight: 100,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 20,
      paddingRight: 20,
    },
    arrow: {
      color: `${colours.offwhite}`,
    },
  })(Tooltip)

  const TooltipContent = (
    <Typography className={classes.popin}>
      La précision des prix indiqués ne prend pas en compte l’environnement et certaines
      caractéristiques techniques de votre bien. L’estimation reflète le niveau d’information dont
      nous disposons.
    </Typography>
  )

  const formatPrice = price =>
    new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)

  return (
    <div className={classes.card}>
      <div className={classes.content}>
        <Typography className={classes.title}>Votre bien est estimé :</Typography>
        <div className={classes.prices}>
          <div className={classes.sidePrice}>
            <Typography className={classes.sidePriceTitle}>Estimation basse</Typography>
            <Typography className={classes.sidePriceValue}>{formatPrice(props.low)}</Typography>
          </div>
          <Typography className={classes.estimation}>{formatPrice(props.price)}</Typography>
          <div className={`${classes.sidePrice} ${classes.sidePriceRight}`}>
            <Typography className={`${classes.sidePriceTitle} ${classes.sidePriceTitleRight}`}>Estimation haute</Typography>
            <Typography className={`${classes.sidePriceValue} ${classes.sidePriceValueRight}`}>{formatPrice(props.high)}</Typography>
          </div>
        </div>
        <div className={classes.averagePrice}>
          <Typography className={classes.averagePriceLabel}>Prix moyen au m²:</Typography>
          <Typography className={classes.averagePriceValue}>
            {formatPrice(props.average)}/m²
          </Typography>
        </div>
        <div className={classes.confidence}>
          <Typography className={classes.confidenceLabel}>Indice de confiance :</Typography>
          <Rating className={classes.rating} score={props.confidence} />
          {matches && (
            <CustomTooltip title={TooltipContent} arrow placement="right">
              <div>
                <SVG src={info} />
              </div>
            </CustomTooltip>
          )}
        </div>
        <Button
          className={classes.button}
          startIcon={<SVG src={email} />}
          onClick={props.onButtonClick}
        >
          <Typography className={classes.buttonText}>
            Recevoir cette estimation par e-mail
          </Typography>
        </Button>
      </div>
      <SVG className={classes.paperclip} src={paperclip} />
      <SVG className={classes.grid} src={grid} />
    </div>
  )
}

export default CardEstimation
