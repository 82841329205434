import React from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { LinearProgress, Typography } from '@material-ui/core'

import logo from '../../assets/icons/allowa-logo.svg'

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    height: 80,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: 75,
    boxShadow: '0 10px 30px -10px rgb(0 0 0 / 10%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '45%',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  progressBar: {
    width: '100%',
    height: 5,
    [theme.breakpoints.down('xs')]: {
      height: 2,
    },
  },
  mainBar: {
    background: theme.palette.primary.main,
  },
  secondaryBar: {
    background: 'transparent',
  },
  underline: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: 17,
    fontWeight: 500,
    marginLeft: 24,
    marginTop: 2,
    [theme.breakpoints.down('xs')]: {
      marginTop: 6,
      fontSize: 12,
    },
  },
}))

type HeaderProps = {
  value?: number
}

function Header({ value }: HeaderProps): React.ReactElement {
  const classes = useStyles()
  return (
    <div className={classes.headerContainer} id="header">
      <div className={classes.header}>
        <div className={classes.content}>
          <img src={logo} height={25} />
          <Typography color="textPrimary" className={classes.title}>
            Estimation immobilière 
            <span className={classes.underline}>gratuite.</span>
          </Typography>
        </div>
      </div>
      <LinearProgress
        className={classes.progressBar}
        classes={{ colorPrimary: classes.secondaryBar, bar: classes.mainBar }}
        variant="determinate"
        value={value ?? 0}
      />
    </div>
  )
}

export default Header
