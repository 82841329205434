import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

type RoundedCheckboxProps = {
  onChange: (value: boolean) => void;
  label: string;
  value: boolean;
  className?: string;
};

const useStyles = makeStyles({
  label: {
    color: '#67727D',
  },
  circle: {
    width: 30,
    height: 30,
    borderRadius: 30,
    border: '1px solid #CFD4DF',
    color: '#26C598',
  },
});

function RoundedCheckbox(props: RoundedCheckboxProps) {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={`${classes.label} ${props.className}`}
      control={
        <Checkbox
          checked={props.value}
          icon={<span className={classes.circle}></span>}
          checkedIcon={
            <span className={classes.circle}>
              <Check />
            </span>
          }
          onChange={e => props.onChange(e?.target.checked)}
        />
      }
      label={props.label}
    />
  );
}

export default RoundedCheckbox;
