import React from 'react'
import SVG from 'react-inlinesvg'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
// Props
import { customButton } from '../utils/constants/types'

const useStyles = makeStyles((theme: Theme) => ({
  primaryButton: {
    border: 0,
    borderRadius: 5,
    fontSize: 15,
    height: 45,
    padding: '0 30px',
    textTransform: 'capitalize',
  },
  disabledButton: {
    background: '#9AA5B0 !important',
    color: 'white !important',
  },
}))

function PrimaryButton(props: customButton): React.ReactElement {
  const classes = useStyles()
  return (
    <Button
      className={`${classes.primaryButton} ${props.className} ${
        props.isDisabled && classes.disabledButton
      }`}
      onClick={props.onClick}
      disableElevation
      color="primary"
      variant="contained"
    >
      {props.icon && <SVG src={props.icon} />}
      {props.label}
    </Button>
  )
}

export default PrimaryButton
