import paths from './paths';
import { ParcoursProps } from '../constants/props';
// Pages
import AddressPage from '../../components/estimation/AddressPage';
import PropertyTypePage from '../../components/estimation/PropertyTypePage';
import SellInfosPage from '../../components/estimation/SellInfosPage';
import RoomsInfosPage from '../../components/estimation/RoomsInfosPage';
import PropertyStatePage from '../../components/estimation/PropertyStatePage';
import PropertyFeaturesPage from '../../components/estimation/PropertyFeaturesPage';
import OwnerInfosPage from '../../components/estimation/OwnerInfosPage';
import SummaryPage from '../../components/estimation/SummaryPage';
import LoadingPage from '../../components/estimation/LoadingPage';
import ResultPage from '../../components/estimation/ResultPage';

type RouteElement = {
  // Path of the page
  path: string;
  // Component to render
  component: (props: ParcoursProps) => React.ReactElement;
  // Either it increase progress bar or not
  progress?: number;
  // Prev button label
  prev?: string;
  // Next button label
  next?: string;
  // Display the footer background or not
  withBackground?: boolean;
  // Clicking on Next Button will prevent to go to next page and display form errors
  blocked?: boolean;
};

const ROUTING: RouteElement[] = [
  { path: paths.form.address, component: AddressPage, progress: 0, withBackground: true },
  {
    path: paths.form.type,
    component: PropertyTypePage,
    progress: 16.6,
    prev: 'Retour',
    next: 'Suivant',
  },
  {
    path: paths.form.owner,
    component: SellInfosPage,
    progress: 33.3,
    prev: 'Étape précédente',
    next: 'Suivant',
  },
  {
    path: paths.form.rooms,
    component: RoomsInfosPage,
    progress: 50,
    prev: 'Étape précédente',
    next: 'Suivant',
  },
  {
    path: paths.form.state,
    component: PropertyStatePage,
    progress: 66.6,
    prev: 'Étape précédente',
    next: 'Suivant',
  },
  {
    path: paths.form.others,
    component: PropertyFeaturesPage,
    progress: 83.3,
    prev: 'Étape précédente',
    next: 'Suivant',
  },
  {
    path: paths.form.infos,
    component: OwnerInfosPage,
    progress: 100,
    prev: 'Étape précédente',
    next: 'Suivant',
    blocked: true,
  },
  {
    path: paths.form.summary,
    component: SummaryPage,
    progress: 100,
    prev: 'Modifier',
    withBackground: true,
  },
  { path: paths.form.loading, component: LoadingPage, progress: 100, withBackground: true },
  { path: paths.form.result, component: ResultPage, progress: 100, withBackground: true },
];

export { ROUTING };
export type { RouteElement };
