const paths = {
  home: '/',
  admin: '/admin',
  form: {
    root: '/parcours',
    address: '/search',
    type: '/type',
    owner: '/proprietaire',
    rooms: '/pieces',
    state: '/etat',
    others: '/caracteristiques',
    infos: '/coordonnees',
    summary: '/recapitulatif',
    loading: '/chargement',
    result: '/estimation-resultat',
  },
};

export default paths;
