import React from 'react';
import SVG from 'react-inlinesvg';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// Props
import { cardServices } from '../utils/constants/types';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    border: '1px solid #DFE4EC',
    borderRadius: 5,
    padding: '10px 20px 20px 20px',
    textAlign: 'center',
    width: 274,
    alignSelf: 'flex-start',
  },
  iconBg: {
    width: 80,
    height: 80,
    backgroundColor: '#F9FBFE',
    borderRadius: 42,
    margin: '20px auto',
  },
  icon: {
    marginTop: 20,
    '& #services-3': {
      marginTop: 25,
    },
  },
  title: {
    marginTop: 10,
    fontSize: 18,
    fontWeight: 'bold',
  },
  text: {
    padding: 10,
  },
});

function CardRating(props: cardServices): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.iconBg}>
        <div className={classes.icon}>
          <SVG src={props.icon} className={classes.icon} />
        </div>
      </div>
      <Typography className={classes.title}> {props.title}</Typography>
      <Typography className={classes.text}>{props.text}</Typography>
    </div>
  );
}

export default CardRating;
