import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { TextField, IconButton } from '@material-ui/core'
import { Remove, Add } from '@material-ui/icons'

type Props = {
  small?: boolean
  currentValue: number
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: props => (props.small ? 160 : 250),
    justifyContent: 'space-between',
    position: 'relative',
  },
  unit: {
    marginLeft: 10,
    color: '#818E9C',
    fontSize: 15,
    fontWeight: 700,
    position: 'absolute',
    right: 44,
  },
  button: props => ({
    background: theme.palette.secondary.main,
    borderRadius: 20,
    color: 'white',
    width: props.small ? 25 : 40,
    height: props.small ? 25 : 40,
    '&:hover': {
      background: theme.palette.secondary.dark,
    },
  }),
  textField: {
    width: props => (props.small ? 82 : 130),
  },
  input: {
    borderRadius: 10,
    height: props => (props.small ? 40 : 60),
    fontWeight: 500,
  },
}))

type NumberInputProps = {
  min?: number
  max?: number
  onChange: (value: number) => void
  value?: number
  small?: boolean
  zeroValue?: string
  unit?: string
}

function NumberInput({ min = 0, ...props }: NumberInputProps): React.ReactElement {
  const currentValue = props.value ?? min
  const zeroValue = props.zeroValue ?? `${min}`

  const add = () => {
    if (props.max && props.value === props.max) {
      return
    }
    props.onChange(currentValue + 1)
  }

  const substract = () => {
    if (props.value === min) {
      return
    }
    props.onChange(currentValue - 1)
  }

  const handleType = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = parseInt(e.target?.value)
    if (isNaN(newValue) || (props.max && newValue > props.max)) {
      return
    }
    props.onChange(newValue)
  }

  const displayValue = () => {
    if (currentValue === min) {
      return zeroValue
    }
    if (currentValue > 0 && currentValue < 10) {
      return '0' + currentValue
    }
    return currentValue
  }

  const classes = useStyles({ small: props.small, currentValue })
  return (
    <div className={classes.container}>
      <IconButton className={classes.button} onClick={substract}>
        <Remove fontSize={props.small ? 'small' : 'inherit'} />
      </IconButton>
      <TextField
        classes={{ root: classes.textField }}
        InputProps={{ classes: { root: classes.input } }}
        variant="outlined"
        value={displayValue()}
        onChange={e => handleType(e)}
      />
      {props.unit && <div className={classes.unit}>{props.unit}</div>}
      <IconButton className={classes.button} onClick={add}>
        <Add fontSize={props.small ? 'small' : 'inherit'} />
      </IconButton>
    </div>
  )
}

export default NumberInput
