import React from 'react'
import { Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'

import { ParcoursProps } from '../../utils/constants/props'
import ChoiceButton from '../../components/common/ChoiceButton'
import renovate from '../../assets/icons/renovate.svg'
import thumb from '../../assets/icons/thumb.svg'
import star from '../../assets/icons/star.svg'
import visavis from '../../assets/icons/visavis.svg'
import window from '../../assets/icons/window.svg'
import homeSun from '../../assets/icons/home-sun.svg'
import { OrientationLabel, Standing, View } from '../../utils/constants/types'
import SelectInput from '../../components/common/SelectInput'

const orientations = [
  { label: OrientationLabel.EAST, value: 1 },
  { label: OrientationLabel.WEST, value: 2 },
  { label: OrientationLabel.NORTH, value: 3 },
  { label: OrientationLabel.SOUTH, value: 4 },
]

const useStyles = makeStyles((theme: Theme) => ({
  stateContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 30,
    '& > *': {
      marginBottom: 10,
      flex: '0 0 25%',
      flexBasis: 'auto',
      marginRight: 40,
      [theme.breakpoints.down('xs')]: {
        marginRight: 20,
      },
    },
  },
  orientationContainer: {
    marginBottom: 30,
  },
  viewContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 30,
    '& > *': {
      marginBottom: 10,
      flex: '0 0 25%',
      flexBasis: 'auto',
      marginRight: 40,
      [theme.breakpoints.down('xs')]: {
        marginRight: 20,
      },
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 100,
    },
  },
}))

function PropertyStatePage(props: ParcoursProps): React.ReactElement {
  const classes = useStyles()
  return (
    <>
      {/* STATE */}
      <Typography gutterBottom style={{ marginBottom: 10 }}>
        {' '}
        Quel est l'état de votre logement ?
      </Typography>
      <div className={classes.stateContainer}>
        <ChoiceButton
          icon={renovate}
          label="A rénover"
          onClick={() => props.setData('standing', Standing.TO_RENOVATE)}
          active={props.data.standing === Standing.TO_RENOVATE}
        />
        <ChoiceButton
          icon={thumb}
          label="Bon état"
          onClick={() => props.setData('standing', Standing.AVERAGE)}
          active={props.data.standing === Standing.AVERAGE}
        />
        <ChoiceButton
          icon={star}
          label="Parfait état"
          onClick={() => props.setData('standing', Standing.HIGH)}
          active={props.data.standing === Standing.HIGH}
        />
      </div>

      {/* ORIENTATION */}
      <div className={classes.orientationContainer}>
        <Typography gutterBottom style={{ marginBottom: 10 }}>
          Quelle est l'orientation de votre bien 🧭?
        </Typography>
        <SelectInput
          label="Orientation"
          width={300}
          value={props.data.orientation?.value}
          data={orientations}
          onChange={value =>
            props.setData(
              'orientation',
              orientations.find(s => s.value === value)
            )
          }
        />
      </div>

      {/* VIEW */}
      <Typography gutterBottom style={{ marginBottom: 10 }}>
        Quelle type de vue dispose votre bien ?
      </Typography>
      <div className={classes.viewContainer}>
        <ChoiceButton
          icon={visavis}
          label="Vis-à-vis"
          onClick={() => props.setData('view', View.OVERLOOKED)}
          active={props.data.view === View.OVERLOOKED}
        />
        <ChoiceButton
          icon={window}
          label="Dégagée"
          onClick={() => props.setData('view', View.CLEAR)}
          active={props.data.view === View.CLEAR}
        />
        <ChoiceButton
          icon={homeSun}
          label="Exceptionnelle"
          onClick={() => props.setData('view', View.AMAZING)}
          active={props.data.view === View.AMAZING}
        />
      </div>
    </>
  )
}

export default PropertyStatePage
