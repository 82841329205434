import React from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core/'
import PlacesAutocomplete, { getLatLng, geocodeByAddress } from 'react-places-autocomplete'
// Props
import { searchInput } from '../../utils/constants/types'
// Assets
import pin from '../../assets/icons/mappin.svg'

const useStyles = makeStyles((theme: Theme) => ({
  inputText: {
    border: '1px solid #DFE4EC',
    borderRadius: 10,
    color: '#001E3F',
    fontFamily: 'Poppins, sans-serif',
    fontSize: 15,
    fontWeight: 500,
    height: 64,
    outline: 'none',
    padding: '0 70px 0 30px',
    textTransform: 'none',
    width: '100%',
    '&:focus': {
      border: '1px solid #7C8C9F',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  inputLabel: { position: 'relative' },
  inputIcon: {
    position: 'absolute',
    right: 30,
    top: 0,
  },
  formField: {
    position: 'relative',
  },
  bottomDiv: {},
  inputBottomText: {
    color: '#67727D',
    fontSize: 11,
    fontStyle: 'italic',
    marginLeft: 30,
  },
  suggestionsBox: {
    position: 'absolute',
    width: 630,
    borderRadius: 6,
    marginTop: 10,
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      width: '100%',
    },
  },
  miniMap: {
    borderRadius: 10,
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export default function SearchInput(props: searchInput): React.ReactElement {
  const classes = useStyles()
  const handleSelect = async value => {
    const results = await geocodeByAddress(value)
    const latLng = await getLatLng(results[0])
    props.setData('address', value)
    props.setData('lat', latLng.lat)
    props.setData('lon', latLng.lng)
  }

  const searchOptions = {
    componentRestrictions: { country: ['fr'] },
  }

  const matches = useMediaQuery('(max-width: 599px)')
  let mapImgDimensions = '630x122'
  if (matches) {
    mapImgDimensions = '630x244'
  }

  const mapImg =
    'https://maps.googleapis.com/maps/api/staticmap?center=' +
    props.data.lat +
    ',' +
    props.data.lon +
    '&markers=color:0xBE1A26%7Clabel:%7C' +
    props.data.lat +
    ',' +
    props.data.lon +
    '&zoom=15&size=' +
    mapImgDimensions +
    '&key=' +
    process.env.GATSBY_REACT_APP_GOOGLE_MAPS_KEY

  return (
    <div className={props.className}>
      <PlacesAutocomplete
        value={props.data.address}
        onChange={value => props.setData('address', value)}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={classes.formField}>
            <form>
              <label className={classes.inputLabel}>
                <input
                  placeholder="Votre adresse"
                  {...getInputProps()}
                  className={classes.inputText}
                />
                <img src={pin} height={25} className={classes.inputIcon} />
              </label>
            </form>
            {props.data.address[0] >= '0' && props.data.address[0] <= '9' && (
              <div className={classes.suggestionsBox}>
                {loading ? null : null}
                {suggestions.map(suggestion => {
                  const style = {
                    backgroundColor: suggestion.active ? '#e6ecf8' : '#ffffff',
                    borderRadius: 5,
                    color: suggestion.active ? '#001E3F' : '#67727D',
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 500,
                    maxWidth: 570,
                    padding: '20px 30px',
                    zIndex: 1,
                  }
                  return (
                    <div {...getSuggestionItemProps(suggestion, { style })}>
                      {suggestion.description}
                    </div>
                  )
                })}
              </div>
            )}
            <div className={classes.bottomDiv}>
              {' '}
              <p className={classes.inputBottomText}>Ex : 12 rue de Brest, 69002 Lyon</p>
              {props.data.lat !== 0 && props.data.lat !== undefined ? (
                <img src={mapImg} className={classes.miniMap} />
              ) : null}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  )
}
