import React from 'react';
import SVG from 'react-inlinesvg';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
// Props
import { customButton } from '../utils/constants/types';

const useStyles = makeStyles((theme: Theme) => ({
  secondaryButton: {
    background: '#FFFFFF',
    border: '1px solid #CFD4DF',
    borderRadius: 10,
    color: '#818E9C',
    fontSize: 15,
    fontWeight: 700,
    height: 100,
    padding: '0 20px',
    textTransform: 'none',
    width: 160,
    lineHeight: 1.3,
    '&:hover': {
      background: '#F0F4FB',
    },
    [theme.breakpoints.down('xs')]: {
      width: 140,
      height: 90,
    },
  },
  selectedButton: {
    background: '#FAE0D6',
    border: '1px solid #FF7057',
    color: '#FF7057',
    '&:hover': {
      background: '#FAE0D6',
    },
  },
  icon: {
    marginBottom: 13,
    '& path, polygon': {
      fill: '#818E9C',
    },
    '& line': {
      stroke: '#818E9C',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 12,
    },
  },
  iconSelected: {
    marginBottom: 13,
    '& path, polygon': {
      fill: '#FF7057',
    },
    '& line': {
      stroke: '#FF7057',
    },
    '& #Group-2 path': {
      stroke: '#FF7057',
    },
  },
  small: {
    height: 70,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedLabel: {
    color: '#FF7057',
  },
}));

function ChoiceButton(props: customButton): React.ReactElement {
  const classes = useStyles();

  return props.checkbox ? (
    <FormControlLabel
      classes={{ label: props.active ? classes.selectedLabel : '' }}
      control={<Checkbox checked={props.active} onChange={props.onClick} />}
      label={props.label}
    />
  ) : (
    <Button
      className={`
        ${classes.secondaryButton}
        ${props.active && classes.selectedButton}
        ${props.small && classes.small}
      `}
      onClick={props.onClick}
      disableElevation
    >
      <div className={classes.content}>
        {props.icon && (
          <SVG src={props.icon} className={props.active ? classes.iconSelected : classes.icon} />
        )}
        {props.label}
      </div>
    </Button>
  );
}

export default ChoiceButton;
