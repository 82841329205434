import React from 'react'
import { Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { ParcoursProps } from '../../utils/constants/props'
import { Property, SellEstimateLabel } from '../../utils/constants/types'
// Components
import ChoiceButton from '../../components/common/ChoiceButton'
import SelectInput from '../../components/common/SelectInput'
// Assets
import primary from '../../assets/icons/main-residency.svg'
import secondary from '../../assets/icons/secondary-residency.svg'
import rental from '../../assets/icons/rental-residency.svg'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 100,
    },
  },
  residencyUsage: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *': {
      marginBottom: 10,
      flex: '0 0 25%',
      flexBasis: 'auto',
      marginRight: 40,
      [theme.breakpoints.down('xs')]: {
        marginRight: 20,
      },
    },
  },
  choiceButton: {
    marginRight: 30,
    [theme.breakpoints.down('xs')]: {
      marginRight: 20,
      marginBottom: 20,
    },
  },
}))

const sellEstimates = [
  { label: SellEstimateLabel.ASAP, value: 1 },
  { label: SellEstimateLabel.THREE_MONTHS, value: 2 },
  { label: SellEstimateLabel.SIX_MONTHS, value: 3 },
  { label: SellEstimateLabel.MORE_SIX_MONTHS, value: 4 },
  { label: SellEstimateLabel.NOT_WILLING_TO_SELL, value: 5 },
]

function SellInfosPage(props: ParcoursProps): React.ReactElement {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div>
        <Typography>Êtes-vous propriétaire du bien estimé ?*</Typography>
        <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
          <div style={{ marginRight: 20 }}>
            <ChoiceButton
              label="Oui"
              small={true}
              active={props.data.owner === true}
              onClick={() => props.setData('owner', true)}
            />
          </div>
          <div>
            <ChoiceButton
              label="Non"
              small={true}
              active={props.data.owner === false}
              onClick={() => props.setData('owner', false)}
            />
          </div>
        </div>
        <Typography style={{ marginTop: 40 }}>Ce bien est une :*</Typography>
        <div className={classes.residencyUsage}>
          <div className={classes.choiceButton}>
            <ChoiceButton
              label="Résidence principale"
              icon={primary}
              active={props.data.property === Property.MAIN}
              onClick={() => props.setData('property', Property.MAIN)}
            />
          </div>
          <div className={classes.choiceButton}>
            <ChoiceButton
              label="Résidence secondaire"
              icon={secondary}
              active={props.data.property === Property.SECONDARY}
              onClick={() => props.setData('property', Property.SECONDARY)}
            />
          </div>
          <div>
            <ChoiceButton
              label="Résidence locative"
              icon={rental}
              active={props.data.property === Property.LOC}
              onClick={() => props.setData('property', Property.LOC)}
            />
          </div>
        </div>
        <Typography style={{ marginTop: 40 }}>Envisagez-vous de vendre ce bien ?*</Typography>{' '}
        <SelectInput
          label="Délai"
          width={300}
          value={props.data.sellEstimate?.value}
          data={sellEstimates}
          onChange={value =>
            props.setData(
              'sellEstimate',
              sellEstimates.find(s => s.value === value)
            )
          }
        />
      </div>
    </div>
  )
}

export default SellInfosPage
