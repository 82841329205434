import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useMediaQuery } from '@material-ui/core';
import { ratingStars } from '../utils/constants/types';
import Rating from '@material-ui/lab/Rating';

const StyledRating = withStyles({
  iconFilled: {
    color: '#FFDB75',
  },
  iconEmpty: {
    color: '#FFFFFF',
  },
})(Rating);

function StarIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M10 15l-4.08438037 2.14728583c-.73362738.38569074-1.59105981-.23727038-1.45094945-1.05417648l.78004724-4.54802438-3.30433313-3.22092875c-.59351702-.5785361-.26600698-1.58650838.55421337-1.70569338l4.56647608-.66354781 2.04219018-4.13792928c.3668137-.74324572 1.42665847-.74324572 1.79347216 0l2.04219018 4.13792928 4.56647608.66354781c.82022035.119185 1.1477304 1.12715727.55421337 1.70569338l-3.30433313 3.22092875.78004724 4.54802438c.14011036.8169061-.71732207 1.43986722-1.45094945 1.05417648L10 15z" />
    </SvgIcon>
  );
}

function CardRating(props: ratingStars): React.ReactElement {
  const matches = useMediaQuery('(max-width: 599px)');
  return (
    <div className={props.className || ''}>
      <StyledRating
        name="read-only"
        value={props.score || 0}
        max={3}
        readOnly
        size={matches ? 'small' : 'large'}
        icon={<StarIcon fontSize="inherit" />}
        emptyIcon={<StarIcon fontSize="inherit" />}
      />
    </div>
  );
}

export default CardRating;
