import React from 'react'
import { Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { ParcoursProps } from '../../utils/constants/props'
import { PropertyType } from '../../utils/constants/types'
// Components
import TextInput from '../../components/common/TextInput'
import NumberInput from '../../components/common/NumberInput'
import ChoiceButton from '../../components/common/ChoiceButton'
// Assets
import maison from '../../assets/icons/maison.svg'
import appartement from '../../assets/icons/appartement.svg'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('xs')]: {
      maxWidth: 360,
    },
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
      maxWidth: 340,
    },
    [theme.breakpoints.down(378)]: {
      maxWidth: 300,
    },
  },
  choiceButton: {
    marginRight: 40,
    [theme.breakpoints.down('xs')]: {
      marginRight: 30,
    },
  },
  textInput: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 450,
    marginTop: 40,
    [theme.breakpoints.down('xs')]: {
      width: 340,
    },
  },
  endOfPage: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 100,
    },
  },
}))

function PropertyTypePage(props: ParcoursProps): React.ReactElement {
  const classes = useStyles()
  const onClickAppartement = () => {
    props.setData('propertyType', PropertyType.apartment)
    props.setData('landArea', 0)
  }

  const onClickMaison = () => {
    props.setData('propertyType', PropertyType.house)
  }

  const convertStringToNumber = (value: string) => {
    const intValue = parseInt(value)
    return isNaN(intValue) ? 0 : intValue
  }

  let small = false
  if (typeof window !== 'undefined' && window.matchMedia('(max-width: 599px)').matches) {
    small = true
  }

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.label} id="">
          Quel type de bien souhaitez-vous estimer ?*
        </Typography>
        <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
          <div className={classes.choiceButton}>
            <ChoiceButton
              label="Maison"
              icon={maison}
              active={props.data.propertyType === PropertyType.house}
              onClick={onClickMaison}
            />
          </div>
          <div>
            <ChoiceButton
              label="Appartement"
              icon={appartement}
              active={props.data.propertyType === PropertyType.apartment}
              onClick={onClickAppartement}
            />
          </div>
        </div>
        <div className={classes.textInput}>
          <TextInput
            type="number"
            label="Quelle est la surface habitable de votre logement ?"
            required
            placeholder="Votre surface habitable en m²"
            value={props.data.livingArea === 0 ? '' : props.data.livingArea}
            max={800}
            min={0}
            onChange={value => props.setData('livingArea', convertStringToNumber(value))}
            error={
              props.data.livingArea > 0 && props.data.livingArea < 5
                ? 'Valeur minimale: 5m²'
                : undefined
            }
          />
          {props.data.propertyType === PropertyType.house && (
            <>
              <div style={{ marginTop: 40 }}>
                <TextInput
                  type="number"
                  label="Quelle est la surface de votre terrain ?"
                  required
                  placeholder="Votre surface de terrain en m²"
                  value={props.data.landArea === 0 ? '' : props.data.landArea}
                  max={5000}
                  onChange={value => props.setData('landArea', convertStringToNumber(value))}
                />
              </div>
              <div style={{ marginTop: 40 }} className={classes.endOfPage}>
                <Typography gutterBottom style={{ marginBottom: 10 }}>
                  Combien y a-t-il d'étages ?*
                </Typography>
                <NumberInput
                  min={0}
                  max={50}
                  zeroValue="Plain-pied"
                  value={props.data.numberOfFloorsInBuilding}
                  onChange={value => props.setData('numberOfFloorsInBuilding', value)}
                  small={small}
                />
              </div>
            </>
          )}
          {props.data.propertyType === PropertyType.apartment && (
            <>
              <div style={{ marginTop: 40 }}>
                <Typography gutterBottom style={{ marginBottom: 10 }}>
                  A quel étage se situe votre bien ?*
                </Typography>
                <NumberInput
                  min={0}
                  max={50}
                  zeroValue="RDC"
                  value={props.data.floorNumber}
                  onChange={value => props.setData('floorNumber', value)}
                  small={small}
                />
              </div>
              <div style={{ marginTop: 40 }} className={classes.endOfPage}>
                <Typography gutterBottom style={{ marginBottom: 10 }}>
                  Combien y a-t-il de niveaux ?*
                </Typography>
                <NumberInput
                  min={0}
                  max={50}
                  value={props.data.numberOfFloorsInBuilding}
                  onChange={value => props.setData('numberOfFloorsInBuilding', value)}
                  small={small}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default PropertyTypePage
