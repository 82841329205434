import { Data } from "../constants/types";
import ResultEstimation from "../models/ResultEstimation";

class EstimationFormatter {
    static formatDataForAirtable(data: Data, result: ResultEstimation) {
        const resultClean = result.valuations[0][0]
        return {
            lon: parseFloat(data.lon!),
            lat: parseFloat(data.lat!),
            address: data.address,
            propertyType: data.propertyType,
            livingArea: data.livingArea,
            bedroomNb: data.bedroomNb,
            cellar: data.cellar,
            buildingYear: data.constructionYearNone ? null : data.buildingYear.value,
            floorNumber: data.floorNumber,
            numberOfFloorsInBuilding: data.numberOfFloorsInBuilding,
            landArea: data.landArea,
            numberOfIndoorParkingSpaces: data.numberOfIndoorParkingSpaces,
            numberOfOutdoorParkingSpaces: data.numberOfOutdoorParkingSpaces,
            numberOfRooms: data.numberOfRooms,
            standing: data.standing,
            hasPool: data.hasPool,
            balconyArea: data.balconyArea,
            view: data.view,
            orientation: data.orientation?.label,
            concierge: data.concierge,
            hasLift: data.hasLift,
            owner: data.owner,
            sellEstimate: data.sellEstimate?.label,
            property: data.property,
            firstName: data.contact.firstName,
            lastName: data.contact.lastName,
            email: data.contact.email,
            phone: data.contact.phone,
            receiveInfosFromAllowa: data.contact.receiveInfosFromAllowa,
            acceptContactFromAllowa: data.contact.acceptContactFromAllowa,
            result: resultClean.salePrice,
            resultLow: resultClean.salePriceRange.lower,
            resultHigh: resultClean.salePriceRange.upper,
            confidence: resultClean.confidence,
            pricem2: resultClean.salePrice / data.livingArea
        }
    }
}

export default EstimationFormatter