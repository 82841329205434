import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    allVariants: {
      fontFamily: `"Poppins", sans-serif`,
      color: '#001E3F',
    },
  },
  palette: {
    primary: {
      main: '#0E47C1',
      dark: '#0B399A',
    },
    text: {
      primary: '#001E3F',
    },
    secondary: {
      main: '#FF7057',
      light: '#F36E34',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#7C8C9F',
        },
      },
    },
  },
});

export default theme;
