import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';

type SummaryProps = {
  data: { label: string; value?: string }[];
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    border: '2px solid #DFE4EC',
    borderRadius: 8,
    width: '100%',
    '& > :nth-child(even)': {
      background: '#E6ECF8',
    },
    '& > :nth-child(odd)': {
      background: '#FFFFFF',
    },
    '& > :first-child': {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    '& > :last-child': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      alignItems: 'baseline',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 100,
    },
  },
  row: {
    alignItems: 'center',
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 15,
    height: 'auto',
    justifyContent: 'space-between',
    padding: '9px 25px',
    '& > *': {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 1.6,
    },
  },
  label: {
    color: '#67727D',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  value: {
    color: '#001E3F',
    maxWidth: '60%',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}));

function Summary(props: SummaryProps): React.ReactElement {
  const classes = useStyles();

  const buildRow = (label, value) => (
    <div className={classes.row}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </div>
  );

  return (
    <Paper elevation={0} className={classes.paper}>
      {props.data.map(d => buildRow(d.label, d.value))}
    </Paper>
  );
}

export default Summary;
