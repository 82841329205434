import React from 'react'
import { Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { ParcoursProps } from '../../utils/constants/props'
// Components
import constructionYears from '../../utils/constants/constructionYear'
import NumberInput from '../../components/common/NumberInput'
import RoundedCheckbox from '../../components/common/RoundedCheckbox'
import SelectInput from '../../components/common/SelectInput'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  numberRow: {
    marginBottom: 35,
  },
  sliderRow: {
    marginBottom: 20,
  },
  checkbox: {
    marginTop: 20,
  },
}))

function RoomsInfosPage(props: ParcoursProps): React.ReactElement {
  const classes = useStyles()
  let small = false
  if (typeof window !== 'undefined' && window.matchMedia('(max-width: 599px)').matches) {
    small = true
  }

  return (
    <div className={classes.container}>
      <div className={classes.numberRow}>
        <Typography gutterBottom style={{ marginBottom: 10 }}>
          Combien y a-t-il de pièces à vivre ?*
        </Typography>
        <NumberInput
          min={1}
          max={20}
          onChange={(value: number) => props.setData('numberOfRooms', value)}
          value={props.data.numberOfRooms}
          small={small}
        />
      </div>
      <div className={classes.numberRow}>
        <Typography gutterBottom style={{ marginBottom: 10 }}>
          Combien y a-t-il de chambres 🛏️ ?*
        </Typography>
        <NumberInput
          min={0}
          max={100}
          onChange={(value: number) => props.setData('bedroomNb', value)}
          value={props.data.bedroomNb}
          small={small}
        />
      </div>
      <div className={classes.sliderRow}>
        <Typography gutterBottom style={{ marginBottom: 10 }}>
          Quelle est son année de construction ?*
        </Typography>
        <div>
          <SelectInput
            label="Année de construction"
            width={300}
            disabled={props.data.constructionYearNone}
            value={props.data.buildingYear?.value}
            data={constructionYears}
            onChange={value =>
              props.setData(
                'buildingYear',
                constructionYears.find(s => s.value === value)
              )
            }
          />
        </div>
        <RoundedCheckbox
          label="Je ne sais pas"
          onChange={(value: boolean) => props.setData('constructionYearNone', value)}
          value={props.data.constructionYearNone}
          className={classes.checkbox}
        />
      </div>
    </div>
  )
}

export default RoomsInfosPage
