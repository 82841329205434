import React, { useEffect, useState } from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import axios, { AxiosResponse } from 'axios'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Helmet from 'react-helmet'

import SEO from '../../components/seo'
import Header from '../../components/estimation/Header'
import { ROUTING, RouteElement } from '../../utils/constants/routing'
import { Data } from '../../utils/constants/types'
import { hasErrors } from '../../utils/validator'
import { DataValue } from '../../utils/constants/props'

// components
import PrimaryButton from '../../components/common/PrimaryButton'
import BackButton from '../../components/common/BackButton'
import constructionYears from '../../utils/constants/constructionYear'
import footer from '../../assets/icons/address-footer.svg'
import paths from '../../utils/constants/paths'
import ResultEstimation from '../../utils/models/ResultEstimation'
import theme from '../../style/EstimationTheme'
import '../../style/global.scss'
import EstimationFormatter from '../../utils/formatters/estimation.formatter'

import { geocodeByAddress } from 'react-places-autocomplete'
const componentStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    marginLeft: 140,
    marginRight: 140,
    display: 'flex',
    // height: 'calc(100vh - 180px)',
    [theme.breakpoints.down('md')]: {
      marginLeft: 40,
      marginRight: 40,
      position: 'relative',
      height: 'calc(100vh - 50px)',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20,
      marginRight: 20,
      marginBottom: 100,
    },
  },
  backButtonContainer: {
    flex: 1,
    marginTop: 50,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // [theme.breakpoints.down('md')]: {
    //   marginTop: 20,
    //   height: 60,
    //   position: 'absolute',
    //   top: 0,
    //   left: -20,
    //   textAlign: 'center',
    // },
    [theme.breakpoints.down('md')]: {
      display: 'block',
      top: 0,
      marginTop: 20,
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      left: -20,
    },
  },
  mainContentContainer: {
    marginTop: 50,
    width: 640,
    paddingLeft: 16,
    [theme.breakpoints.down('md')]: {
      marginTop: 80,
      maxWidth: 600,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 70,
      width: '100%',
    },
  },
  nextButtonContainer: {
    flex: 1,
    marginTop: 100,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      '& > *': {
        position: 'fixed',
        left: 0,
        width: '100vw',
        bottom: 0,
        height: '50px',
        zIndex: 5,
        borderRadius: 0,
      },
    },
  },
  globalContainer: {
    height: '100vh',
    overflow: 'auto',
    // [theme.breakpoints.down('md')]: {
    //   width: '100vw',
    // },
  },
  backgroundImg: {
    backgroundImage: `url(${footer})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundAttachment: 'fixed',
  },
}))

export function urlUpdate(url) {
  if (typeof window !== `undefined` && typeof url !== `undefined`) {
    window.history.pushState({}, '', url)
  }
  return
}

function Parcours(initProps: any): React.ReactElement {
  const classes = componentStyles()
  const [formError, setFormError] = useState(true)
  const [errorNextPage, setErrorNextPage] = useState(false)
  const [currentPage, setCurrentPage] = useState<RouteElement>(ROUTING[0])

  const [result, setResult] = useState<ResultEstimation | undefined>()

  const [data, setData] = useState<Data>({
    address: '',
    lon: undefined,
    lat: undefined,
    propertyType: undefined,
    livingArea: 0,
    landArea: 0,
    floorNumber: 0,
    numberOfFloorsInBuilding: 0,
    owner: undefined,
    property: undefined,
    sellEstimate: undefined,
    numberOfRooms: 1,
    bedroomNb: 0,
    buildingYear: constructionYears[0],
    constructionYearNone: false,
    standing: undefined,
    orientation: undefined,
    view: undefined,
    balconyArea: 0,
    numberOfIndoorParkingSpaces: 0,
    numberOfOutdoorParkingSpaces: 0,
    hasLift: false,
    cellar: false,
    concierge: false,
    hasPool: false,
    contact: {
      gender: undefined,
      lastName: '',
      firstName: '',
      email: '',
      phone: '',
      receiveInfosFromAllowa: false,
      acceptContactFromAllowa: false,
    },
  })

  const currentRoute = ROUTING.find(p => p.path === currentPage?.path)

  // Check error on each page and on each change in data
  useEffect(() => {
    const invalid = hasErrors(currentPage.path, data)
    setFormError(invalid)
  }, [currentPage, data])

  useEffect(() => {
    const search = initProps?.location?.search
    if (!search || search.length === 0) {
      return
    }
    const paramsTmp = new URLSearchParams(search)
    const params = {
      lat: paramsTmp.get('lat'),
      lon: paramsTmp.get('lon'),
      address: paramsTmp.get('address') as string,
    }
    if (!params.address) {
      return
    } else {
      let lat = params.lat
      let lon = params.lon
      if (!lat || !lon) {
        geocodeByAddress(params.address).then(res => {
          const geocodeAddress = res[0]
          lat = geocodeAddress.geometry.location.lat()
          lon = geocodeAddress.geometry.location.lng()

          setData(prev => ({
            ...prev,
            address: params.address,
            lat: (lat as unknown) as number,
            lon: (lon as unknown) as number,
          }))
          return
        })
      }
      setData(prev => ({
        ...prev,
        address: params.address,
        lat: (lat as unknown) as number,
        lon: (lon as unknown) as number,
      }))
    }
  }, [])

  const nextPage = async (prev = false) => {
    // If we're going to next page, handle validation
    if (!prev) {
      const invalid = hasErrors(currentPage.path, data)
      setFormError(invalid)
      if (invalid) {
        if (currentRoute?.blocked) {
          setErrorNextPage(true)
        }
        return
      }
    }
    // Get index of targetted page (prev or next)
    const nextPageIndex = ROUTING.findIndex(p => p.path === currentPage?.path) + (prev ? -1 : 1)
    // If we want to go to next page but it doesn't exist
    if (!prev && nextPageIndex > ROUTING.length) {
      return
    }
    // If we want to go to previous page but it doesn't exist
    if (prev && nextPageIndex < 0) {
      return
    }
    const nextPage = ROUTING[nextPageIndex]

    // If going to loading, send the data
    if (nextPage.path === paths.form.loading && data.lat && data.lon) {
      setCurrentPage(nextPage)

      urlUpdate(`/estimation${nextPage.path}`)
      const credentialsData = { username: 'stephane.genty', password: 'dW8DqH48Z9' }
      const tokenData = await axios.post(
        'https://api.pricehubble.com/auth/login/credentials',
        credentialsData
      )

      const estimationData = {
        dealType: 'sale',
        countryCode: 'FR',
        valuationInputs: [
          {
            property: {
              location: {
                coordinates: {
                  latitude: parseFloat(data.lat),
                  longitude: parseFloat(data.lon),
                },
              },
              propertyType: {
                code: data.propertyType === 'Appartement' ? 'apartment' : 'house',
              },
              buildingYear: data.buildingYear.value,
              livingArea: data.livingArea,
              landArea: data.propertyType === 'Appartement' ? undefined : data.landArea,
              numberOfRooms: data.numberOfRooms,
              balconyArea: data.balconyArea,
              numberOfIndoorParkingSpaces: data.numberOfIndoorParkingSpaces,
              numberOfOutdoorParkingSpaces: data.numberOfOutdoorParkingSpaces,
              floorNumber: data.propertyType === 'Appartement' ? data.floorNumber : undefined,
              numberOfFloorsInBuilding: data.numberOfFloorsInBuilding + 1,
              hasLift: data.hasLift,
              hasPool: data.hasPool,
            },
          },
        ],
      }
      const estimation: AxiosResponse<ResultEstimation> = await axios.post(
        'https://api.pricehubble.com/api/v1/valuation/property_value?access_token=' +
          tokenData.data.access_token,
        estimationData
      )

      setResult(estimation.data)

      const headersAirtable = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`,
      }
      await axios.post(
        process.env.GATSBY_AIRTABLE_API_URL!,
        {
          fields: EstimationFormatter.formatDataForAirtable(data, estimation.data),
        },
        { headers: headersAirtable }
      )
    }
    setCurrentPage(nextPage)
    urlUpdate(`/estimation${nextPage.path}`)
    scrollTo('#header')
  }

  const updateData = (item: string, value: DataValue) => {
    setData(prev => ({
      ...prev,
      [item]: value,
    }))
  }

  const page = ROUTING.find(p => p.path === currentPage.path) || ROUTING[0]

  return (
    <ThemeProvider theme={theme}>
      <div
        className={`${classes.globalContainer}
      ${currentPage.withBackground ? classes.backgroundImg : ''} `}
      >
        <SEO
          title={`Estimateur de biens`}
          description={`En quelques étapes, nous pouvons évaluer le prix de votre logement.`}
          keywords={`estimateur de biens, estimateur de biens allowa, estimation prix vente, estimation vente allowa, estimation immobilière gratuite, estimation immobilière gratuite en ligne`}
          pathname={`estimation`}
        />
        <Helmet>
          <script>{`
(function(window, document, dataLayerName, id) {
window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
tags.async=!0,tags.src="https://allowa.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
!function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
})(window, document, 'dataLayer', '61ff81e9-3e95-4589-b7bf-1eb44247409b');
`}</script>
        </Helmet>
        <Header value={currentRoute?.progress} />
        <div className={classes.pageContainer}>
          <div className={classes.backButtonContainer}>
            {currentRoute?.prev && (
              <BackButton label={currentRoute?.prev} onClick={() => nextPage(true)} />
            )}
          </div>
          <div className={classes.mainContentContainer}>
            <page.component
              data={data}
              setData={updateData}
              currentPage={currentPage}
              changePage={nextPage}
              error={errorNextPage}
              result={currentRoute?.path === paths.form.result ? result : undefined}
            />
          </div>
          <div className={classes.nextButtonContainer}>
            {currentRoute?.next && (
              <PrimaryButton
                label={currentRoute?.next}
                isDisabled={formError}
                onClick={() => nextPage(false)}
              />
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Parcours
