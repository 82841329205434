import React, { useState, useEffect } from 'react'
import { Typography, Fade } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import { Checkmark } from 'react-checkmark';

const test = typeof window !== `undefined` ? require('react-checkmark') : null

import { ParcoursProps } from '../../utils/constants/props'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%%',
  },
  rowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  rowAnimated: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 50,
  },
  title: {
    fontSize: 25,
    marginBottom: 50,
    fontWeight: 500,
    width: '90%',
  },
  itemLabel: {
    fontSize: 15,
    marginLeft: 30,
  },
})

const steps = [
  'Relève des prix actuels du marché',
  'Récupération des données historiques des transactions',
  "Intégration des dernières données du marché de l'immobilier",
]

const Checkmark = test?.Checkmark
const animationDelay = 1500

function LoadingPage(props: ParcoursProps): React.ReactElement {
  const [currentSteps, setCurrentSteps] = useState<string[]>([])

  useEffect(() => {
    startAnimation()
  }, [])

  const startAnimation = () => {
    let localIndex = 0
    const timer = setInterval(() => {
      if (localIndex === steps.length) {
        clearInterval(timer)
        props.changePage(false)
        return
      }
      setCurrentSteps(prev => [...prev, steps[localIndex]])
      localIndex++
    }, animationDelay)
  }

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Fade timeout={1000} in={true}>
        <Typography variant="h4" className={classes.title}>
          Nous calculons la valeur de votre bien...
        </Typography>
      </Fade>
      <div className={classes.rowsContainer}>
        {currentSteps.map((step, index) => (
          <div key={index} className={classes.rowAnimated}>
            <div>{Checkmark && <Checkmark size="44px" color="#0E47C1" />}</div>
            <Fade timeout={1000} in={true}>
              <Typography className={classes.itemLabel}>{step}</Typography>
            </Fade>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LoadingPage
