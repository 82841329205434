import React from 'react'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import routes from '../../utils/routes'
import { colours } from '../../style/variables'

import { ParcoursProps } from '../../utils/constants/props'
import SelectInput from '../../components/common/SelectInput'
import { Gender, GenderLabel } from '../../utils/constants/types'
import RoundedCheckbox from '../../components/common/RoundedCheckbox'
import TextInput from '../../components/common/TextInput'
import { isValidEmail, isValidPhone } from '../../utils/validator'
import formErrors from '../../utils/constants/formErrors'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 500,
    marginBottom: 30,
  },
  infosContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 20,
    '& > *': {
      display: 'flex',
      marginTop: 30,
      flex: '0 0 41%',
      [theme.breakpoints.down('xs')]: {
        flex: '100%',
      },
    },
  },
  checkbox: {
    marginTop: 15,
  },
  subtext: {
    color: `${colours.black}`,
    fontSize: 12,
    marginBottom: 60,
    marginLeft: 37,
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      marginBottom: 100,
      marginTop: 10,
    },
  },
  link: {
    color: `${colours.allowaBlue}`,
    textDecoration: 'none',
    '&:hover': {
      color: `${colours.orange}`,
    },
  },
}))

const civilities: Gender[] = [
  { label: GenderLabel.M, value: 1 },
  { label: GenderLabel.F, value: 2 },
]

function OwnerInfosPage(props: ParcoursProps): React.ReactElement {
  const getError = (value, type) => {
    if (value === undefined || value?.length === 0) {
      return formErrors.REQUIRED_FIELD
    }
    if (type === 'email' && !isValidEmail(value)) {
      return formErrors.INVALID_MAIL
    }
    if (type === 'tel' && !isValidPhone(value)) {
      return formErrors.INVALID_PHONE
    }
    return undefined
  }

  const buildTextInput = (label, placeholder, property, type = 'text') => (
    <TextInput
      type={type}
      label={label}
      required
      error={props.error ? getError(props.data.contact[property], type) : undefined}
      placeholder={placeholder}
      value={props.data.contact[property]}
      onChange={value =>
        props.setData('contact', {
          ...props.data.contact,
          [property]: value,
        })
      }
    />
  )

  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="h5" gutterBottom>
        Quelles sont vos coordonnées ?
      </Typography>
      <div>
        <Typography>Civilité*</Typography>
        <SelectInput
          label="Civilité"
          width={160}
          value={props.data.contact.gender?.value}
          data={civilities}
          error={props.error ? getError(props.data.contact.gender, 'gender') : undefined}
          onChange={value =>
            props.setData('contact', {
              ...props.data.contact,
              gender: civilities.find(c => c.value === value),
            })
          }
        />
      </div>

      <div className={classes.infosContainer}>
        {buildTextInput('Nom', 'Votre nom', 'lastName')}
        {buildTextInput('Prénom', 'Votre prénom', 'firstName')}
        {buildTextInput('E-mail', 'Votre adresse e-mail', 'email', 'email')}
        {buildTextInput('Téléphone', 'Votre numéro de téléphone', 'phone', 'tel')}
      </div>
      <RoundedCheckbox
        label="Je souhaite être recontacté par un conseiller Allowa pour mon projet de vente"
        value={props.data.contact.acceptContactFromAllowa}
        className={classes.checkbox}
        onChange={value =>
          props.setData('contact', {
            ...props.data.contact,
            acceptContactFromAllowa: value,
          })
        }
      />
      <RoundedCheckbox
        label="J'accepte de recevoir des informations de la part d'Allowa"
        value={props.data.contact.receiveInfosFromAllowa}
        className={classes.checkbox}
        onChange={value =>
          props.setData('contact', {
            ...props.data.contact,
            receiveInfosFromAllowa: value,
          })
        }
      />
      <div className={classes.subtext}>
        En cliquant sur suivant, vous acceptez nos{' '}
        <Link to={routes.cgu} target="_blank" className={classes.link}>
          Conditions Générales
        </Link>{' '}
        et notre{' '}
        <Link to={routes.privacy_policy} target="_blank" className={classes.link}>
          Politique de confidentialité
        </Link>
        .
      </div>
    </div>
  )
}

export default OwnerInfosPage
