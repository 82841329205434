import React from 'react'
import { Typography, useMediaQuery } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'

import { ParcoursProps } from '../../utils/constants/props'
import ChoiceButton from '../../components/common/ChoiceButton'
import NumberInput from '../../components/common/NumberInput'
import terrace from '../../assets/icons/terrace.svg'
import parking from '../../assets/icons/parking.svg'
import parkingExt from '../../assets/icons/parking-ext.svg'
import concierge from '../../assets/icons/concierge.svg'
import cellar from '../../assets/icons/cellar.svg'
import elevator from '../../assets/icons/elevator.svg'
import swimming from '../../assets/icons/swimming.svg'
import { PropertyType } from '../../utils/constants/types'

const useStyles = makeStyles((theme: Theme) => ({
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 40,
    flex: '0 0 25%',
    '& > :nth-child(odd)': {
      marginBottom: 30,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
      },
    },
    '& > :nth-child(even)': {
      marginBottom: 50,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 20,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  topContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  bottomContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      display: 'flex',
      marginBottom: 30,
      marginRight: 40,
      flex: '0 0 25%',
      flexBasis: 'auto',
      [theme.breakpoints.down('xs')]: {
        marginRight: 20,
        marginBottom: 10,
      },
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 100,
      flexDirection: 'column',
    },
  },
}))

function PropertyFeaturesPage(props: ParcoursProps): React.ReactElement {
  const matches = useMediaQuery('(max-width: 599px)')
  const classes = useStyles()
  const buildColumn = (label, icon, name, min, max, unit?) => (
    <div className={classes.flexColumn}>
      <ChoiceButton
        icon={icon}
        label={label}
        onClick={() => props.setData(name, props.data[name] > 0 ? 0 : 1)}
        active={props.data[name] > 0}
        checkbox={matches}
      />
      {props.data[name] > 0 && (
        <NumberInput
          small
          unit={unit ? unit : ''}
          value={props.data[name]}
          min={min}
          max={max}
          onChange={value => props.setData(name, value)}
        />
      )}
    </div>
  )
  return (
    <>
      <Typography gutterBottom style={{ marginBottom: 10 }}>
        Avez-vous d'autres caractéristiques à ajouter ?
      </Typography>
      <div className={classes.topContainer}>
        {buildColumn('Terrasse/Balcon', terrace, 'balconyArea', 0, 200, 'm²')}
        {buildColumn('Parking intérieur', parking, 'numberOfIndoorParkingSpaces', 0, 2)}
        {buildColumn('Parking extérieur', parkingExt, 'numberOfOutdoorParkingSpaces', 0, 2)}
      </div>
      <div className={classes.bottomContainer}>
        {props.data.propertyType === PropertyType.apartment && (
          <ChoiceButton
            icon={elevator}
            label="Ascenseur"
            onClick={() => props.setData('hasLift', !props.data.hasLift)}
            active={props.data.hasLift}
            checkbox={matches}
          />
        )}
        <ChoiceButton
          icon={cellar}
          label="Cave"
          onClick={() => props.setData('cellar', !props.data.cellar)}
          active={props.data.cellar}
          checkbox={matches}
        />
        {props.data.propertyType === PropertyType.apartment && (
          <ChoiceButton
            icon={concierge}
            label="Gardien"
            onClick={() => props.setData('concierge', !props.data.concierge)}
            active={props.data.concierge}
            checkbox={matches}
          />
        )}
        {props.data.propertyType === PropertyType.house && (
          <ChoiceButton
            icon={swimming}
            label="Piscine"
            onClick={() => props.setData('hasPool', !props.data.hasPool)}
            active={props.data.hasPool}
            checkbox={matches}
          />
        )}
      </div>
    </>
  )
}

export default PropertyFeaturesPage
