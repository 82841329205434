import React from 'react'
import { Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { ParcoursProps } from '../../utils/constants/props'
import SearchInput from '../../components/common/SearchInput'
import PrimaryButton from '../../components/common/PrimaryButton'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      maxWidth: 600,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 360,
    },
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      textAlign: 'center',
      width: '100%',
    },
  },
  subtitle: {
    marginTop: 5,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 13,
      width: '100%',
    },
  },
  searchInputTitle: {
    marginTop: 80,
    marginLeft: 5,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'center',
      textAlign: 'center',
      fontSize: 15,
      marginTop: 40,
      marginLeft: 0,
    },
  },
  searchInput: {
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
    },
  },
}))

function AddressPage(props: ParcoursProps): React.ReactElement {
  const classes = useStyles()
  const toNextPage = () => {
    if (props.data.lat !== undefined) {
      props.changePage(false)
      return
    }
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        Bienvenue sur l’estimateur de biens Allowa 👋
      </Typography>
      <Typography className={classes.subtitle}>
        En quelques étapes, nous pouvons évaluer{' '}
        <span style={{ color: '#0E47C1' }}>le prix de votre logement.</span>
      </Typography>
      <Typography className={classes.searchInputTitle}>
        Quelle est l’adresse de votre bien 🏠 ?*
      </Typography>
      <div className={classes.searchInput}>
        <SearchInput data={props.data} setData={props.setData} />
      </div>
      <div style={{ marginTop: 40, marginBottom: 40, textAlign: 'center' }}>
        <PrimaryButton
          label="Commencer"
          onClick={toNextPage}
          isDisabled={props.data.lat === undefined}
        />
      </div>
    </div>
  )
}

export default AddressPage
