import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { Typography, Snackbar, useMediaQuery } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

import { ParcoursProps } from '../../utils/constants/props'
import { PropertyType } from '../../utils/constants/types'
// Components
import CardRating from '../../components/estimation/CardRating'
import CardEstimation from '../../components/estimation/CardEstimation'
import CardServices from '../../components/estimation/CardServices'
import PrimaryButton from '../../components/common/PrimaryButton'
// Assets
import services1 from '../../assets/icons/services-1.svg'
import services2 from '../../assets/icons/services-2.svg'
import services3 from '../../assets/icons/services-3.svg'
import maison from '../../assets/icons/maison.svg'
import appartement from '../../assets/icons/appartement.svg'
import mappin from '../../assets/icons/mappin.svg'
import surface from '../../assets/icons/surface.svg'
import rooms from '../../assets/icons/rooms.svg'
import agent from '../../assets/icons/agent.svg'
import list from '../../assets/icons/list.svg'
import email from '../../assets/icons/email.svg'
import axios from 'axios'
import { navigate } from 'gatsby'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 20,
    },
  },
  topTitle: {
    fontSize: 25,
    marginBottom: 20,
    fontWeight: 500,
    marginTop: -50,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  topDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
    textAlign: 'center',
    width: '125%',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '70%',
      justifyContent: 'space-around',
      '& > *': {
        flexWrap: 'wrap',
        flex: '0 0 40%',
        flexBasis: 'auto',
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  containerFlexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  propertyTypeIconBg: {
    backgroundColor: '#E4EDFF',
    borderRadius: 44,
    height: 65,
    width: 65,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  addressField: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  propertyTypeIcon: {
    height: 35,
    marginTop: 14,
    width: 'auto',
    '& path': {
      fill: '#0E47C1',
    },
  },
  propertyTypeIconMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      height: 28,
      marginTop: 18,
      width: 'auto',
      display: 'block',
    },
  },
  userAddress: {
    fontWeight: 500,
    marginLeft: 20,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '280px',
      marginLeft: 12,
    },
  },
  smallPropertyIcon: {
    height: 23,
    marginTop: 20,
  },
  agentIcon: {
    height: 48,
    width: 48,
  },
  agentTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 20,
    marginTop: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  listContainer: {
    marginTop: 20,
  },
  agentList: {
    height: 250,
    marginLeft: 10,
    width: 30,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  agentListText: {
    flexDirection: 'column',
    marginLeft: 30,
    maxWidth: 480,
    [theme.breakpoints.down('xs')]: {
      '& > *': {
        fontSize: 13,
        marginLeft: 50,
      },
    },
  },
  reviewSection: {
    marginBottom: 50,
    marginTop: 50,
  },
  reviewCard: {
    alignSelf: 'center',
    marginTop: 20,
  },
  bottomButton: {
    marginTop: 30,
    textTransform: 'none',
  },
  dot: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      width: 25,
      height: 25,
      background: theme.palette.primary.main,
      borderRadius: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      position: 'absolute',
      left: 15,
      marginTop: 5,
    },
  },
  servicesCard: {
    marginTop: 40,
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'row',
    height: '0%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  serviceCard: {
    marginRight: 35,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      marginBottom: 20,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8,
    },
  },
  backButton: {
    display: 'flex',
    margin: 'auto',
    marginTop: 36,
    marginBottom: 16,
  },
}))

type UserRatingElement = {
  userName: string
  userReview: string
}

const UserRatings: UserRatingElement[] = [
  {
    userName: 'Justine P.',
    userReview:
      "Excellent sens du service, tout s’est très passé du début à la fin, que ce soit lors des visites ou lors des échanges téléphoniques. Le représentant s’est toujours montré disponible, à l'écoute et surtout très accueillant. Si je devais recommander, je le ferais très  certainement.",
  },
  {
    userName: 'Philippe B.',
    userReview:
      'Très pro, très orienté au client, équipe agréable. Nous avons été très bien accompagnés.',
  },
  {
    userName: 'Géraldine K.',
    userReview: 'Très bon service ! Rapide et efficace. Merci !',
  },
  {
    userName: 'Louis D.',
    userReview: "Top service, 5 étoiles parce qu'il n'y en a pas 6 !",
  },
  {
    userName: 'Jeremy L.',
    userReview: 'Bonne agence ! Disponibles, avenantes et professionnelles.',
  },
]

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function ResultPage(props: ParcoursProps): React.ReactElement {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const matches = useMediaQuery('(max-width: 599px)')
  const result = props.result?.valuations[0][0]

  const [randomReview] = useState<UserRatingElement>(
    UserRatings[Math.floor(Math.random() * UserRatings.length)]
  )

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  function formatPrice(price?: number) {
    if (!price || isNaN(price)) {
      return 'NaN'
    }
    return Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(Math.round(price / 100) * 100)
  }

  const sendEmail = async () => {
    setOpen(true)
    await axios.post('https://api.allowa.com/v1/estimation/notify', {
      estimation: {
        email: props.data.contact.email,
        address: props.data.address,
        propertySurface: props.data.livingArea,
        roomNb: props.data.numberOfRooms,
        result: formatPrice(result?.salePrice),
        resultLow: formatPrice(result?.salePriceRange.lower),
        resultHigh: formatPrice(result?.salePriceRange.upper),
        confidence: result?.confidence === 'good' ? 'Bon' : 'Modéré',
        pricem2: result?.salePrice ? result?.salePrice / props.data.livingArea : 'NC',
      },
    })
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.topTitle}>Votre estimation 👇</Typography>
      <div className={classes.topDiv}>
        <div className={classes.containerFlexRow}>
          <div className={classes.propertyTypeIconBg}>
            {props.data.propertyType === PropertyType.apartment && (
              <SVG src={appartement} className={classes.propertyTypeIcon} />
            )}
            {props.data.propertyType === PropertyType.house && (
              <SVG src={maison} className={classes.propertyTypeIcon} />
            )}
          </div>
          <div className={classes.addressField}>
            <SVG src={mappin} className={classes.propertyTypeIconMobile} />
            <Typography className={classes.userAddress}>{props.data.address}</Typography>
          </div>
        </div>
        <div className={classes.containerFlexRow}>
          <SVG src={surface} className={classes.smallPropertyIcon} />
          <Typography className={classes.userAddress}>Surface {props.data.livingArea}m²</Typography>
        </div>
        <div className={classes.containerFlexRow}>
          <SVG src={rooms} className={classes.smallPropertyIcon} />
          <Typography className={classes.userAddress}>
            {props.data.numberOfRooms} {props.data.numberOfRooms > 1 && ' pièces'}{' '}
            {props.data.numberOfRooms <= 1 && ' pièce'}
          </Typography>
        </div>
      </div>
      <div>
        <CardEstimation
          price={Math.round((props.result?.valuations[0]?.[0].salePrice ?? 0) / 100) * 100}
          low={Math.round((props.result?.valuations[0]?.[0].salePriceRange.lower ?? 0) / 100) * 100}
          high={
            Math.round((props.result?.valuations[0]?.[0].salePriceRange.upper ?? 0) / 100) * 100
          }
          confidence={
            props.result?.valuations[0]?.[0].confidence === 'poor'
              ? 1
              : props.result?.valuations[0]?.[0].confidence === 'medium'
              ? 2
              : props.result?.valuations[0]?.[0].confidence === 'good'
              ? 3
              : 0
          }
          average={Math.round(
            (props.result?.valuations[0]?.[0].salePrice ?? 0) / props.data.livingArea
          )}
          onButtonClick={() => sendEmail()}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        {props.data.sellEstimate?.value !== 5 && (
          <div>
            <div className={classes.containerFlexRow}>
              <SVG src={agent} className={classes.agentIcon} />
              <Typography className={classes.agentTitle}>C'est quoi la suite ?</Typography>
            </div>
            <div className={`${classes.containerFlexRow} ${classes.listContainer}`}>
              <SVG src={list} className={classes.agentList} />
              <div className={classes.agentListText}>
                <Typography style={{ marginBottom: 24 }}>
                  <div className={classes.dot}>1</div>
                  Un de nos agents vous recontactera pour prendre rendez-vous et viendra visiter
                  votre bien dans le respect des mesures sanitaires afin de vous faire une
                  estimation précise.
                </Typography>
                <Typography style={{ marginBottom: 24 }}>
                  <div className={classes.dot}>2</div>
                  Nous lancerons la commercialisation de votre bien et sa diffusion sur les
                  meilleurs sites immobiliers.
                </Typography>
                <Typography>
                  <div className={classes.dot}>3</div>
                  Nous prendrons en charge les visites et la vente. Pas d’inquiétude on vous
                  accompagnera jusqu’à la signature chez le notaire.
                </Typography>
              </div>
            </div>

            <div
              className={classes.reviewSection}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Typography style={{ fontWeight: 500, textAlign: 'center' }}>
                Ils nous ont fait{' '}
                <span style={{ color: '#0E47C1', textDecoration: 'underline' }}>confiance !</span>
              </Typography>
              <div className={classes.reviewCard}>
                <CardRating name={randomReview.userName} text={randomReview.userReview} />
              </div>
            </div>

            <PrimaryButton
              label="Revenir à l'accueil"
              onClick={() => navigate('/')}
              className={classes.backButton}
            />
          </div>
        )}

        {props.data.sellEstimate?.value === 5 && (
          <Typography>
            <div>
              <div>
                <Typography style={{ fontSize: 25, textAlign: 'center', fontWeight: 500 }}>
                  Nos services
                </Typography>
                <div className={classes.servicesCard}>
                  <div className={classes.serviceCard}>
                    <CardServices
                      icon={services1}
                      title="Accompagnement dédié"
                      text="Une première estimation sera effectuée en ligne puis afin d'affiner notre estimation, un agent viendra visiter votre bien"
                    />
                  </div>
                  <div className={classes.serviceCard}>
                    <CardServices
                      icon={services2}
                      title="Service complet"
                      text="Réalisation de photos, mise en ligne de votre annonces sur les meilleurs sites immobiliers, prise en charge des visites. Nos agents s'occupent de tout jusqu'à la sélection des meilleures offres"
                    />
                  </div>
                  <div className={classes.serviceCard}>
                    <CardServices
                      icon={services3}
                      title="C’est vendu !"
                      text="Nos équipes vous obtiendrons la meilleure offre et vous accompagne jusqu'à la signature chez le notaire."
                    />
                  </div>
                </div>
              </div>
              <div
                className={classes.reviewSection}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Typography style={{ fontWeight: 500, textAlign: 'center' }}>
                  Ils nous ont fait{' '}
                  <span style={{ color: '#0E47C1', textDecoration: 'underline' }}>confiance !</span>
                </Typography>
                <div className={classes.reviewCard} style={{ alignSelf: 'center' }}>
                  <CardRating
                    name="Justine P."
                    text="Excellent sens du service, tout s’est très passé du début à la fin, que ce soit lors des visites ou lors des échanges téléphoniques. Le représentant s’est toujours montré disponible, à l'écoute et surtout très accueillant. Si je devais recommander, je le ferais très  certainement."
                  />
                </div>
              </div>
              <PrimaryButton label="Revenir à l'accueil" onClick={() => navigate('/')} />
            </div>
          </Typography>
        )}
      </div>
      {matches && (
        <PrimaryButton
          icon={email}
          label="Recevoir cette estimation par email"
          className={classes.bottomButton}
          onClick={() => sendEmail()}
        />
      )}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          L'estimation a bien été envoyé à votre adresse email
        </Alert>
      </Snackbar>
    </div>
  )
}

export default ResultPage
