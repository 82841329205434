import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

type SelectProps = {
  data: { value: number; label: string }[];
  label: string;
  value?: number | string;
  onChange: (value: number) => void;
  width?: number;
  disabled?: boolean;
  error?: string;
};

type Props = {
  width?: number;
};

const useStyles = makeStyles<Theme, Props>(theme => ({
  menu: {
    marginTop: 10,
    boxShadow: 'none',
    borderRadius: 10,
    border: '1px solid #DFE4EC',
    '& ul': {
      padding: 0,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    width: props => (props.width ? props.width : '100%'),
    '&.MuiSelect-icon': {
      color: 'blue',
    },
  },
  select: {
    borderRadius: 10,
    background: 'white',
    height: 65,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  list: {
    '& li': {
      borderBottom: '1px solid #E6ECF8',
      height: 50,
    },
    '& .Mui-selected': {
      background: '#E6ECF8',
      borderBottom: '1px solid #E6ECF8',
    },
  },
  error: {
    color: '#f44336',
    fontSize: 13.5,
    marginLeft: 14,
    marginTop: 3,
    width: 180,
  },
}));

function SelectInput(props: SelectProps): React.ReactElement {
  const classes = useStyles({ width: props.width });
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      {/* Workaround: hide label when a value is selected */}
      <InputLabel shrink={false} style={{ marginTop: 5 }}>
        {props.value ? '' : props.label}
      </InputLabel>
      <Select
        IconComponent={KeyboardArrowDown}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          classes: { paper: classes.menu, list: classes.list },
        }}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
        value={props.value || ''}
        onChange={(e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
          props.onChange(e.target.value as number)
        }
        className={classes.select}
        disabled={props.disabled}
        error={props.error !== undefined}
      >
        {props.data.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {props.error &&
        <div className={classes.error}>
          {props.error}
        </div>
      }
    </FormControl>
  );
}

export default SelectInput;
