import { ConstructionYear, ConstructionYearLabel } from '../../utils/constants/types';

const constructionYears: ConstructionYear[] = [
  { value: 1900, label: ConstructionYearLabel.BEFORE_1900 },
  { value: 1905, label: ConstructionYearLabel._1900_1910 },
  { value: 1915, label: ConstructionYearLabel._1910_1920 },
  { value: 1925, label: ConstructionYearLabel._1920_1930 },
  { value: 1935, label: ConstructionYearLabel._1930_1940 },
  { value: 1945, label: ConstructionYearLabel._1940_1950 },
  { value: 1955, label: ConstructionYearLabel._1950_1960 },
  { value: 1965, label: ConstructionYearLabel._1960_1970 },
  { value: 1975, label: ConstructionYearLabel._1970_1980 },
  { value: 1985, label: ConstructionYearLabel._1980_1990 },
  { value: 1995, label: ConstructionYearLabel._1990_2000 },
  { value: 2005, label: ConstructionYearLabel._2000_2010 },
  { value: 2015, label: ConstructionYearLabel.AFTER_2010 },
];

export default constructionYears;
